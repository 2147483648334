
// Client services rest api calling

import { routes } from '../config/api_routes'

export const SaasClientService = {
    // get saasClientList 

    getSaasClientListing: (formData) => {    
        return window.axios.post(routes.getSaasClientApi,formData)
    },

    getSaasClientById: (formData) => {
        return window.axios.post(routes.getSaasClientByIdApi,formData)
    },

    updateSaasClient : (formData) => {
        return window.axios.post(routes.updateSaasClientApi,formData)
    }
}